@charset "UTF-8";
/* line 11, ../../../src/scss/screen.scss */
* {
  margin: 0;
  padding: 0;
}

/* line 17, ../../../../../../usr/local/rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* line 22, ../../../../../../usr/local/rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
html {
  line-height: 1;
}

/* line 24, ../../../../../../usr/local/rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
ol, ul {
  list-style: none;
}

/* line 26, ../../../../../../usr/local/rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* line 28, ../../../../../../usr/local/rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

/* line 30, ../../../../../../usr/local/rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
q, blockquote {
  quotes: none;
}
/* line 103, ../../../../../../usr/local/rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

/* line 32, ../../../../../../usr/local/rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
a img {
  border: none;
}

/* line 116, ../../../../../../usr/local/rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
}

/* line 61, ../../../../../../usr/local/rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/typography/_vertical_rhythm.scss */
* html {
  font-size: 112.5%;
}

/* line 64, ../../../../../../usr/local/rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/typography/_vertical_rhythm.scss */
html {
  font-size: 18px;
  line-height: 1.33333em;
}

/* line 37, ../../../src/scss/screen.scss */
html {
  overflow-y: auto;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  background-color: #fff;
}

/* line 45, ../../../src/scss/screen.scss */
body {
  text-align: center;
  overflow: hidden;
}

/* line 56, ../../../src/scss/screen.scss */
html,
body,
button,
input,
select,
textarea {
  font-size: 18px;
  word-spacing: 1px;
  line-height: 24px;
  font-family: "freightsans_regular", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  text-transform: none;
  letter-spacing: 0;
  color: #6b6b6b;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}
/* line 67, ../../../src/scss/screen.scss */
html ::selection,
body ::selection,
button ::selection,
input ::selection,
select ::selection,
textarea ::selection {
  background: #ee6557;
  color: #fff;
}
/* line 71, ../../../src/scss/screen.scss */
html ::-moz-selection,
body ::-moz-selection,
button ::-moz-selection,
input ::-moz-selection,
select ::-moz-selection,
textarea ::-moz-selection {
  background: #16a6b6;
  color: #fff;
}

/* line 79, ../../../src/scss/screen.scss */
#page-border {
  background: whitesmoke;
  margin: 20px;
}

/* line 88, ../../../src/scss/screen.scss */
.container {
  position: relative;
  margin: 0 auto;
}

/* line 100, ../../../src/scss/screen.scss */
.grid-1, .grid-2,
.grid-3, .grid-4,
.grid-5, .grid-6,
.grid-7, .grid-8,
.grid-9, .grid-10,
.grid-11, .grid-12,
.grid-13, .grid-14,
.grid-15, .grid-16 {
  float: left;
  display: inline;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}
/* line 107, ../../../src/scss/screen.scss */
.grid-1.alpha, .grid-2.alpha,
.grid-3.alpha, .grid-4.alpha,
.grid-5.alpha, .grid-6.alpha,
.grid-7.alpha, .grid-8.alpha,
.grid-9.alpha, .grid-10.alpha,
.grid-11.alpha, .grid-12.alpha,
.grid-13.alpha, .grid-14.alpha,
.grid-15.alpha, .grid-16.alpha {
  margin-left: 0;
}
/* line 111, ../../../src/scss/screen.scss */
.grid-1.omega, .grid-2.omega,
.grid-3.omega, .grid-4.omega,
.grid-5.omega, .grid-6.omega,
.grid-7.omega, .grid-8.omega,
.grid-9.omega, .grid-10.omega,
.grid-11.omega, .grid-12.omega,
.grid-13.omega, .grid-14.omega,
.grid-15.omega, .grid-16.omega {
  margin-right: 0;
}
/* line 115, ../../../src/scss/screen.scss */
.grid-1.center, .grid-2.center,
.grid-3.center, .grid-4.center,
.grid-5.center, .grid-6.center,
.grid-7.center, .grid-8.center,
.grid-9.center, .grid-10.center,
.grid-11.center, .grid-12.center,
.grid-13.center, .grid-14.center,
.grid-15.center, .grid-16.center {
  float: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* line 51, ../../../src/scss/_utils.scss */
.container {
  width: 1120px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-1 {
  width: 50px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-1.large {
  width: 62.5px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-2 {
  width: 120px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-2.large {
  width: 150px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-3 {
  width: 190px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-3.large {
  width: 237.5px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-4 {
  width: 260px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-4.large {
  width: 325px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-5 {
  width: 330px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-5.large {
  width: 412.5px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-6 {
  width: 400px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-6.large {
  width: 500px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-7 {
  width: 470px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-7.large {
  width: 587.5px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-8 {
  width: 540px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-8.large {
  width: 675px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-9 {
  width: 610px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-9.large {
  width: 762.5px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-10 {
  width: 680px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-10.large {
  width: 850px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-11 {
  width: 750px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-11.large {
  width: 937.5px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-12 {
  width: 820px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-12.large {
  width: 1025px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-13 {
  width: 890px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-13.large {
  width: 1112.5px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-14 {
  width: 960px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-14.large {
  width: 1200px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-15 {
  width: 1030px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-15.large {
  width: 1287.5px;
}

/* line 62, ../../../src/scss/_utils.scss */
.grid-16 {
  width: 1100px;
}
/* line 65, ../../../src/scss/_utils.scss */
.grid-16.large {
  width: 1375px;
}

/* line 127, ../../../src/scss/screen.scss */
#wrap,
.wrap {
  padding: 20px 0;
}

/* line 131, ../../../src/scss/screen.scss */
.vertical-center {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  height: 100%;
  z-index: -1;
  position: relative;
  vertical-align: middle;
}

/* line 160, ../../../src/scss/screen.scss */
ul, ol, dl,
p,
blockquote,
cite,
hr,
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
form,
input,
fieldset,
.input-wrap,
textarea,
.margin-top-standard,
.circ {
  margin-top: 1em;
}
/* line 163, ../../../src/scss/screen.scss */
ul:first-child, ol:first-child, dl:first-child,
p:first-child,
blockquote:first-child,
cite:first-child,
hr:first-child,
h1:first-child, .h1:first-child,
h2:first-child, .h2:first-child,
h3:first-child, .h3:first-child,
h4:first-child, .h4:first-child,
h5:first-child, .h5:first-child,
h6:first-child, .h6:first-child,
form:first-child,
input:first-child,
fieldset:first-child,
.input-wrap:first-child,
textarea:first-child,
.margin-top-standard:first-child,
.circ:first-child {
  margin-top: 0;
}

/* line 168, ../../../src/scss/screen.scss */
.section {
  margin-top: 150px;
}
/* line 172, ../../../src/scss/screen.scss */
.section.slim {
  margin-top: 110px;
}

/* line 178, ../../../src/scss/screen.scss */
.subsection {
  padding-top: 40px;
  padding-bottom: 60px;
  border-top: 1px solid #6a6a65;
}

/* line 189, ../../../src/scss/screen.scss */
.pseudo-link,
a {
  -webkit-transition: all 275ms;
  -moz-transition: all 275ms;
  -o-transition: all 275ms;
  transition: all 275ms;
  cursor: pointer;
  text-decoration: none;
  color: #181818;
  border-bottom: 1px solid #16a6b6;
}
/* line 200, ../../../src/scss/screen.scss */
.pseudo-link:hover, .pseudo-link:focus, .pseudo-link:active, .pseudo-link.active,
a:hover,
a:focus,
a:active,
a.active {
  color: #16a6b6;
}
/* line 203, ../../../src/scss/screen.scss */
.pseudo-link:hover .pseudo-link, .pseudo-link:focus .pseudo-link, .pseudo-link:active .pseudo-link, .pseudo-link.active .pseudo-link,
a:hover .pseudo-link,
a:focus .pseudo-link,
a:active .pseudo-link,
a.active .pseudo-link {
  color: #16a6b6;
}
/* line 208, ../../../src/scss/screen.scss */
.pseudo-link.secondary,
a.secondary {
  border: none;
  color: #ee6557;
}
/* line 215, ../../../src/scss/screen.scss */
.pseudo-link.secondary:hover, .pseudo-link.secondary:focus, .pseudo-link.secondary:active, .pseudo-link.secondary.active,
a.secondary:hover,
a.secondary:focus,
a.secondary:active,
a.secondary.active {
  color: #cb2514;
}
/* line 220, ../../../src/scss/screen.scss */
.pseudo-link.disabled,
a.disabled {
  pointer-events: none;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 237, ../../../src/scss/screen.scss */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "freightsans_regular", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #181818;
}
/* line 241, ../../../src/scss/screen.scss */
h1.secondary, .h1.secondary,
h2.secondary, .h2.secondary,
h3.secondary, .h3.secondary,
h4.secondary, .h4.secondary,
h5.secondary, .h5.secondary,
h6.secondary, .h6.secondary {
  color: #ee6557;
}

/* line 246, ../../../src/scss/screen.scss */
h1, .h1 {
  font-size: 34px;
  word-spacing: 1px;
  line-height: 40px;
}

/* line 251, ../../../src/scss/screen.scss */
h2, .h2 {
  font-size: 24px;
  word-spacing: 1px;
  line-height: 30px;
}

/* line 256, ../../../src/scss/screen.scss */
h3, .h3 {
  font-size: 20px;
  word-spacing: 1px;
  line-height: 22px;
}

/* line 261, ../../../src/scss/screen.scss */
h4, .h4 {
  font-size: 18px;
  word-spacing: 1px;
  line-height: 20px;
}

/* line 266, ../../../src/scss/screen.scss */
h5, .h5 {
  font-size: 16px;
  word-spacing: 1px;
  line-height: 18px;
}

/* line 271, ../../../src/scss/screen.scss */
h6, .h6 {
  font-size: 12px;
  word-spacing: 1px;
  line-height: 14px;
}

/* line 276, ../../../src/scss/screen.scss */
blockquote {
  font-size: 28px;
  word-spacing: 1px;
  line-height: 36px;
}

/* line 281, ../../../src/scss/screen.scss */
cite {
  display: block;
  clear: both;
  color: #aaaaaa !important;
}

/* line 287, ../../../src/scss/screen.scss */
hr {
  border: none;
  height: 1px;
  background-color: #aaaaaa;
}

/* line 293, ../../../src/scss/screen.scss */
.highlight {
  color: #ee6557;
}

/* line 298, ../../../src/scss/screen.scss */
p.large,
.large-text {
  font-size: 20px;
  word-spacing: 1px;
  line-height: 30px;
}

/* line 304, ../../../src/scss/screen.scss */
p.secondary,
.secondary-text {
  color: #181818;
}

/* line 308, ../../../src/scss/screen.scss */
.meta {
  border-bottom: 1px solid #16a6b6;
}

/* line 318, ../../../src/scss/screen.scss */
.list-regular li {
  padding-top: 8px;
}
/* line 321, ../../../src/scss/screen.scss */
.list-regular li:first-child {
  padding-top: 0;
}

/* line 326, ../../../src/scss/screen.scss */
.list-inline-block > li {
  margin-top: 0;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  vertical-align: top;
}

/* line 332, ../../../src/scss/screen.scss */
.list-inline > li {
  margin-top: 0;
  list-style-type: none;
}
/* line 5, ../../../../../../usr/local/rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/typography/lists/_inline-list.scss */
.list-inline > li, .list-inline > li li {
  margin: 0px;
  padding: 0px;
  display: inline;
}

/* line 337, ../../../src/scss/screen.scss */
.list-float-none > li {
  float: none;
}

/* line 341, ../../../src/scss/screen.scss */
.list-small > li {
  font-size: 12px;
  word-spacing: 1px;
  line-height: 14px;
  vertical-align: top;
}

/**
 *	.list-blocks
 *	------------
 *
 *	Description: Rectangular, centered block grid
 *	Usage: Food
 */
/* line 354, ../../../src/scss/screen.scss */
.list-blocks {
  text-align: center;
  margin: 1.4em 0;
}
/* line 358, ../../../src/scss/screen.scss */
.list-blocks > li {
  margin-top: 10px;
  margin-bottom: 10px;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  float: none;
  height: 275px;
  background-color: #d1d1d1;
}
/* line 372, ../../../src/scss/screen.scss */
.list-blocks.instagram li {
  max-width: 612px;
  height: 612px;
}
/* line 377, ../../../src/scss/screen.scss */
.list-blocks.instagram a {
  display: block;
  border: none;
}

/* line 384, ../../../src/scss/screen.scss */
.list-hex-grid {
  margin-top: 55px;
  margin-bottom: 55px;
  font-size: 0px;
  word-spacing: 1px;
  word-spacing: 0;
}
/* line 390, ../../../src/scss/screen.scss */
.list-hex-grid.with-labels {
  margin-bottom: 200px;
}
/* line 394, ../../../src/scss/screen.scss */
.list-hex-grid img {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  vertical-align: top;
}
/* line 399, ../../../src/scss/screen.scss */
.list-hex-grid div.hex {
  font-size: 16px;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  margin-top: 37px;
  margin-bottom: 37px;
  float: none;
  position: relative;
  z-index: 10;
}
/* line 162, ../../../src/scss/_utils.scss */
.list-hex-grid div.hex > a,
.list-hex-grid div.hex > div {
  height: 110px;
  line-height: 220px;
}
/* line 168, ../../../src/scss/_utils.scss */
.list-hex-grid div.hex .inner {
  top: -55px;
}
/* line 173, ../../../src/scss/_utils.scss */
.list-hex-grid div.hex .hex-1:before,
.list-hex-grid div.hex .hex-2:before {
  height: 220px;
  width: 220px;
}
/* line 178, ../../../src/scss/_utils.scss */
.list-hex-grid div.hex .hex-1:before {
  -webkit-transform: rotate(-60deg) translate(-110px, 0);
  -moz-transform: rotate(-60deg) translate(-110px, 0);
  -ms-transform: rotate(-60deg) translate(-110px, 0);
  -o-transform: rotate(-60deg) translate(-110px, 0);
  transform: rotate(-60deg) translate(-110px, 0);
}
/* line 182, ../../../src/scss/_utils.scss */
.list-hex-grid div.hex .hex-2:before {
  -webkit-transform: rotate(60deg) translate(40px, 40px);
  -moz-transform: rotate(60deg) translate(40px, 40px);
  -ms-transform: rotate(60deg) translate(40px, 40px);
  -o-transform: rotate(60deg) translate(40px, 40px);
  transform: rotate(60deg) translate(40px, 40px);
}
/* line 408, ../../../src/scss/screen.scss */
.list-hex-grid div.hex > a,
.list-hex-grid div.hex > div {
  font-size: 18px;
  word-spacing: 1px;
  -webkit-transition: none 1s;
  -moz-transition: none 1s;
  -o-transition: none 1s;
  transition: none 1s;
  background-color: whitesmoke;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-background-size: auto 220px;
  -moz-background-size: auto 220px;
  -o-background-size: auto 220px;
  background-size: auto 220px;
  text-align: center;
  display: block;
  border: none;
  color: #fff;
  -webkit-backface-visibility: hidden;
}
/* line 432, ../../../src/scss/screen.scss */
.list-hex-grid div.hex p {
  color: #fff;
  font-size: 16px;
}
/* line 437, ../../../src/scss/screen.scss */
.list-hex-grid div.hex .inner {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  white-space: normal;
  z-index: 20;
  position: relative;
  max-width: 80%;
  color: #fff !important;
  font-family: "freightsans_regular", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 22px;
}
/* line 453, ../../../src/scss/screen.scss */
.list-hex-grid div.hex .inner img {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
}
/* line 464, ../../../src/scss/screen.scss */
.list-hex-grid div.hex .hex-1,
.list-hex-grid div.hex .hex-2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  overflow: hidden;
  background: inherit;
  -webkit-backface-visibility: hidden;
}
/* line 477, ../../../src/scss/screen.scss */
.list-hex-grid div.hex .hex-1:before,
.list-hex-grid div.hex .hex-2:before {
  content: '';
  position: absolute;
  background: inherit;
  left: 0;
  z-index: -1;
  background: inherit;
}
/* line 486, ../../../src/scss/screen.scss */
.list-hex-grid div.hex .hex-1 .after,
.list-hex-grid div.hex .hex-2 .after {
  z-index: -1;
}
/* line 491, ../../../src/scss/screen.scss */
.list-hex-grid div.hex .hex-1 {
  z-index: -1;
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  -o-transform: rotate(60deg);
  transform: rotate(60deg);
}
/* line 495, ../../../src/scss/screen.scss */
.list-hex-grid div.hex .hex-1:before {
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
}
/* line 500, ../../../src/scss/screen.scss */
.list-hex-grid div.hex .hex-2 {
  -webkit-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
  transform: rotate(-60deg);
}
/* line 503, ../../../src/scss/screen.scss */
.list-hex-grid div.hex .hex-2:before {
  bottom: 0;
}
/* line 509, ../../../src/scss/screen.scss */
.list-hex-grid div.hex .after {
  -webkit-transition: none 1s;
  -moz-transition: none 1s;
  -o-transition: none 1s;
  transition: none 1s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  background: #16a6b6;
}
/* line 522, ../../../src/scss/screen.scss */
.list-hex-grid div.hex.primary > a,
.list-hex-grid div.hex.primary > div {
  background-color: #16a6b6;
}
/* line 526, ../../../src/scss/screen.scss */
.list-hex-grid div.hex.primary .after {
  background-color: #16a6b6;
  border-color: #16a6b6;
}
/* line 535, ../../../src/scss/screen.scss */
.list-hex-grid div.hex.secondary > a,
.list-hex-grid div.hex.secondary > div {
  background-color: #ee6557;
}
/* line 539, ../../../src/scss/screen.scss */
.list-hex-grid div.hex.secondary .after {
  background-color: #ee6557;
  border-color: #ee6557;
}
/* line 548, ../../../src/scss/screen.scss */
.list-hex-grid div.hex.tertiary > a,
.list-hex-grid div.hex.tertiary > div {
  background-color: #bfbfbf;
}
/* line 552, ../../../src/scss/screen.scss */
.list-hex-grid div.hex.tertiary .after {
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}
/* line 561, ../../../src/scss/screen.scss */
.list-hex-grid div.hex.quaternary > a,
.list-hex-grid div.hex.quaternary > div {
  background-color: #fff;
}
/* line 565, ../../../src/scss/screen.scss */
.list-hex-grid div.hex.quaternary .after {
  background-color: #fff;
  border-color: #fff;
}
/* line 573, ../../../src/scss/screen.scss */
.list-hex-grid div.hex.plain-bg > a,
.list-hex-grid div.hex.plain-bg > div {
  background-color: #fff;
  border-color: #fff;
}
/* line 580, ../../../src/scss/screen.scss */
.list-hex-grid div.hex.invert .after, .list-hex-grid div.hex.invert .inner {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: none 1s;
  -moz-transition: none 1s;
  -o-transition: none 1s;
  transition: none 1s;
}
/* line 595, ../../../src/scss/screen.scss */
.list-hex-grid div.hex.inline-bg img {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100%;
}
/* line 608, ../../../src/scss/screen.scss */
.list-hex-grid div.hex.full-img img {
  max-height: 140%;
  width: auto;
}
/* line 615, ../../../src/scss/screen.scss */
.list-hex-grid hr {
  background-color: #fff;
}
/* line 619, ../../../src/scss/screen.scss */
.list-hex-grid h3 {
  color: #fff;
}
/* line 623, ../../../src/scss/screen.scss */
.list-hex-grid h3.flex {
  font-size: 1.25em;
  line-height: 1.375em;
}
/* line 628, ../../../src/scss/screen.scss */
.list-hex-grid h6.below {
  position: absolute;
  width: 100%;
  margin-top: 0;
  bottom: -84px;
}
/* line 635, ../../../src/scss/screen.scss */
.list-hex-grid .h1.large {
  font-size: 2em;
  line-height: 1.4;
}
/* line 640, ../../../src/scss/screen.scss */
.list-hex-grid.large div.hex {
  margin-top: 37px;
  margin-bottom: 37px;
  margin-top: 47px;
  margin-bottom: 47px;
}
/* line 162, ../../../src/scss/_utils.scss */
.list-hex-grid.large div.hex > a,
.list-hex-grid.large div.hex > div {
  height: 150px;
  line-height: 300px;
}
/* line 168, ../../../src/scss/_utils.scss */
.list-hex-grid.large div.hex .inner {
  top: -75px;
}
/* line 173, ../../../src/scss/_utils.scss */
.list-hex-grid.large div.hex .hex-1:before,
.list-hex-grid.large div.hex .hex-2:before {
  height: 300px;
  width: 300px;
}
/* line 178, ../../../src/scss/_utils.scss */
.list-hex-grid.large div.hex .hex-1:before {
  -webkit-transform: rotate(-60deg) translate(-150px, 0);
  -moz-transform: rotate(-60deg) translate(-150px, 0);
  -ms-transform: rotate(-60deg) translate(-150px, 0);
  -o-transform: rotate(-60deg) translate(-150px, 0);
  transform: rotate(-60deg) translate(-150px, 0);
}
/* line 182, ../../../src/scss/_utils.scss */
.list-hex-grid.large div.hex .hex-2:before {
  -webkit-transform: rotate(60deg) translate(55px, 55px);
  -moz-transform: rotate(60deg) translate(55px, 55px);
  -ms-transform: rotate(60deg) translate(55px, 55px);
  -o-transform: rotate(60deg) translate(55px, 55px);
  transform: rotate(60deg) translate(55px, 55px);
}
/* line 646, ../../../src/scss/screen.scss */
.list-hex-grid.large div.hex > a,
.list-hex-grid.large div.hex > div {
  -webkit-background-size: auto 300px;
  -moz-background-size: auto 300px;
  -o-background-size: auto 300px;
  background-size: auto 300px;
}
/* line 651, ../../../src/scss/screen.scss */
.list-hex-grid.mid div.hex {
  margin-top: 37px;
  margin-bottom: 37px;
  width: 220px;
  margin-top: 40px;
  margin-bottom: 40px;
}
/* line 162, ../../../src/scss/_utils.scss */
.list-hex-grid.mid div.hex > a,
.list-hex-grid.mid div.hex > div {
  height: 127px;
  line-height: 254px;
}
/* line 168, ../../../src/scss/_utils.scss */
.list-hex-grid.mid div.hex .inner {
  top: -63.5px;
}
/* line 173, ../../../src/scss/_utils.scss */
.list-hex-grid.mid div.hex .hex-1:before,
.list-hex-grid.mid div.hex .hex-2:before {
  height: 254px;
  width: 254px;
}
/* line 178, ../../../src/scss/_utils.scss */
.list-hex-grid.mid div.hex .hex-1:before {
  -webkit-transform: rotate(-60deg) translate(-127px, 0);
  -moz-transform: rotate(-60deg) translate(-127px, 0);
  -ms-transform: rotate(-60deg) translate(-127px, 0);
  -o-transform: rotate(-60deg) translate(-127px, 0);
  transform: rotate(-60deg) translate(-127px, 0);
}
/* line 182, ../../../src/scss/_utils.scss */
.list-hex-grid.mid div.hex .hex-2:before {
  -webkit-transform: rotate(60deg) translate(47px, 47px);
  -moz-transform: rotate(60deg) translate(47px, 47px);
  -ms-transform: rotate(60deg) translate(47px, 47px);
  -o-transform: rotate(60deg) translate(47px, 47px);
  transform: rotate(60deg) translate(47px, 47px);
}
/* line 658, ../../../src/scss/screen.scss */
.list-hex-grid.mid div.hex > a,
.list-hex-grid.mid div.hex > div {
  -webkit-background-size: auto 260px;
  -moz-background-size: auto 260px;
  -o-background-size: auto 260px;
  background-size: auto 260px;
}
/* line 665, ../../../src/scss/screen.scss */
.list-hex-grid.modal-active div.hex > a,
.list-hex-grid.modal-active div.hex > div {
  -webkit-backface-visibility: visible;
}
/* line 669, ../../../src/scss/screen.scss */
.list-hex-grid.modal-active div.hex > a .hex-1,
.list-hex-grid.modal-active div.hex > a .hex-2,
.list-hex-grid.modal-active div.hex > div .hex-1,
.list-hex-grid.modal-active div.hex > div .hex-2 {
  -webkit-backface-visibility: visible;
}

/* line 678, ../../../src/scss/screen.scss */
.no-csstransforms .list-hex-grid > br {
  display: none !important;
}
/* line 682, ../../../src/scss/screen.scss */
.no-csstransforms .list-hex-grid div.hex {
  margin-top: 10px;
  margin-bottom: 10px;
}
/* line 689, ../../../src/scss/screen.scss */
.no-csstransforms .list-hex-grid div.hex .hex-1,
.no-csstransforms .list-hex-grid div.hex .hex-2,
.no-csstransforms .list-hex-grid div.hex .hex-1:before,
.no-csstransforms .list-hex-grid div.hex .hex-2:before {
  display: none !important;
}
/* line 694, ../../../src/scss/screen.scss */
.no-csstransforms .list-hex-grid div.hex > a,
.no-csstransforms .list-hex-grid div.hex > div {
  padding: 50px 0;
}
/* line 698, ../../../src/scss/screen.scss */
.no-csstransforms .list-hex-grid div.hex.inline-bg {
  overflow: hidden;
}
/* line 702, ../../../src/scss/screen.scss */
.no-csstransforms .list-hex-grid div.hex.inline-bg > a,
.no-csstransforms .list-hex-grid div.hex.inline-bg > div {
  overflow: hidden;
}
/* line 706, ../../../src/scss/screen.scss */
.no-csstransforms .list-hex-grid div.hex.inline-bg img {
  top: 9%;
  left: 12%;
  max-width: 75%;
}

/* line 716, ../../../src/scss/screen.scss */
.list-icon-items {
  margin-top: -0.9em;
  word-spacing: 0;
}
/* line 720, ../../../src/scss/screen.scss */
.list-icon-items > li {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  float: none;
  vertical-align: top;
}
/* line 726, ../../../src/scss/screen.scss */
.list-icon-items.large-margins > li {
  margin: 2.75em 1em 0;
}
/* line 730, ../../../src/scss/screen.scss */
.list-icon-items.no-margins {
  margin-top: -100px;
}
/* line 733, ../../../src/scss/screen.scss */
.list-icon-items.no-margins > li {
  margin: 2.75em 0 0;
}
/* line 738, ../../../src/scss/screen.scss */
.list-icon-items h6 {
  margin-top: 1.5em;
}
/* line 742, ../../../src/scss/screen.scss */
.list-icon-items i {
  position: relative;
}
/* line 748, ../../../src/scss/screen.scss */
.list-icon-items .pulse {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: none;
  border: 1px solid #ee6557;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 275ms;
  -moz-transition-duration: 275ms;
  -o-transition-duration: 275ms;
  transition-duration: 275ms;
  -webkit-transition-timing-function: cubic-bezier(0.53, -0.67, 0.73, 0.74);
  -moz-transition-timing-function: cubic-bezier(0.53, -0.67, 0.73, 0.74);
  -o-transition-timing-function: cubic-bezier(0.53, -0.67, 0.73, 0.74);
  transition-timing-function: cubic-bezier(0.53, -0.67, 0.73, 0.74);
}
/* line 762, ../../../src/scss/screen.scss */
.list-icon-items a {
  border: none;
  display: block;
}
/* line 766, ../../../src/scss/screen.scss */
.list-icon-items a .pseudo-link {
  word-wrap: break-word;
  word-break: break-all;
}
/* line 774, ../../../src/scss/screen.scss */
.list-icon-items a:hover .button, .list-icon-items a:focus .button, .list-icon-items a:active .button {
  background-color: #16a6b6;
}
/* line 778, ../../../src/scss/screen.scss */
.list-icon-items a:hover .pulse, .list-icon-items a:focus .pulse, .list-icon-items a:active .pulse {
  opacity: 1;
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
  -webkit-transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
  -moz-transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
  -o-transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
  transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
}
/* line 787, ../../../src/scss/screen.scss */
.list-icon-items li.secondary .pulse {
  border: 1px solid #d1d1d1;
}
/* line 793, ../../../src/scss/screen.scss */
.list-icon-items .button,
.list-icon-items button {
  -webkit-backface-visibility: hidden;
}

/* line 799, ../../../src/scss/screen.scss */
.no-csstransitions .pulse {
  display: none;
}

/* line 812, ../../../src/scss/screen.scss */
img,
video,
object {
  display: block;
  max-width: 100%;
  height: auto;
}

@-moz-keyframes loading {
  /* line 820, ../../../src/scss/screen.scss */
  0% {
    -moz-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }

  /* line 824, ../../../src/scss/screen.scss */
  60% {
    -moz-transform: rotate(108deg) scale(1.2);
    transform: rotate(108deg) scale(1.2);
  }

  /* line 828, ../../../src/scss/screen.scss */
  100% {
    -moz-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}

@-webkit-keyframes loading {
  /* line 820, ../../../src/scss/screen.scss */
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }

  /* line 824, ../../../src/scss/screen.scss */
  60% {
    -webkit-transform: rotate(108deg) scale(1.2);
    transform: rotate(108deg) scale(1.2);
  }

  /* line 828, ../../../src/scss/screen.scss */
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}

@-o-keyframes loading {
  /* line 820, ../../../src/scss/screen.scss */
  0% {
    -o-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }

  /* line 824, ../../../src/scss/screen.scss */
  60% {
    -o-transform: rotate(108deg) scale(1.2);
    transform: rotate(108deg) scale(1.2);
  }

  /* line 828, ../../../src/scss/screen.scss */
  100% {
    -o-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}

@-ms-keyframes loading {
  /* line 820, ../../../src/scss/screen.scss */
  0% {
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }

  /* line 824, ../../../src/scss/screen.scss */
  60% {
    -ms-transform: rotate(108deg) scale(1.2);
    transform: rotate(108deg) scale(1.2);
  }

  /* line 828, ../../../src/scss/screen.scss */
  100% {
    -ms-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}

@keyframes loading {
  /* line 820, ../../../src/scss/screen.scss */
  0% {
    transform: rotate(0deg) scale(1);
  }

  /* line 824, ../../../src/scss/screen.scss */
  60% {
    transform: rotate(108deg) scale(1.2);
  }

  /* line 828, ../../../src/scss/screen.scss */
  100% {
    transform: rotate(360deg) scale(1);
  }
}

/* line 833, ../../../src/scss/screen.scss */
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 32px;
  height: 28px;
  line-height: 32px;
  margin: -16px 0 0 -16px;
  color: #16a6b6;
  -webkit-animation-duration: 1.4s;
  -moz-animation-duration: 1.4s;
  -ms-animation-duration: 1.4s;
  -o-animation-duration: 1.4s;
  animation-duration: 1.4s;
  -webkit-animation-name: loading;
  -moz-animation-name: loading;
  -ms-animation-name: loading;
  -o-animation-name: loading;
  animation-name: loading;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-backface-visibility: hidden;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -ms-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  display: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
}
/* line 850, ../../../src/scss/screen.scss */
.loading.active {
  display: block;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}
/* line 855, ../../../src/scss/screen.scss */
.loading.secondary {
  color: #ee6557;
}

/* line 860, ../../../src/scss/screen.scss */
.slider-wrap .loading {
  height: 42px;
  font-size: 50px;
  line-height: 50px;
  top: 30%;
}

/* line 877, ../../../src/scss/screen.scss */
button,
.button {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 275ms;
  -moz-transition-duration: 275ms;
  -o-transition-duration: 275ms;
  transition-duration: 275ms;
  font-size: 16px;
  word-spacing: 1px;
  font-family: "freightsans_regular", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 3px;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  padding: 8px 20px;
  background-color: #16a6b6;
  color: #fff;
  border: none;
  cursor: pointer;
  text-align: center;
}
/* line 896, ../../../src/scss/screen.scss */
button:hover, button:active, button:focus,
.button:hover,
.button:active,
.button:focus {
  color: #fff;
  background-color: #181818;
}
/* line 901, ../../../src/scss/screen.scss */
button.active,
.button.active {
  color: #fff;
  background-color: #ee6557;
}
/* line 907, ../../../src/scss/screen.scss */
button.secondary,
.button.secondary {
  background-color: #ee6557;
}
/* line 912, ../../../src/scss/screen.scss */
button.secondary:hover, button.secondary:active, button.secondary:focus,
.button.secondary:hover,
.button.secondary:active,
.button.secondary:focus {
  background-color: #181818;
}
/* line 916, ../../../src/scss/screen.scss */
button.secondary.active,
.button.secondary.active {
  background-color: #16a6b6;
}
/* line 922, ../../../src/scss/screen.scss */
button.tertiary,
.button.tertiary {
  background-color: #d1d1d1;
}
/* line 928, ../../../src/scss/screen.scss */
button.tertiary:hover, button.tertiary:active, button.tertiary:focus, button.tertiary.active,
.button.tertiary:hover,
.button.tertiary:active,
.button.tertiary:focus,
.button.tertiary.active {
  background-color: #ee6557;
}
/* line 934, ../../../src/scss/screen.scss */
button.quaternary,
.button.quaternary {
  background-color: #98e5fe;
}
/* line 940, ../../../src/scss/screen.scss */
button.quaternary:hover, button.quaternary:active, button.quaternary:focus, button.quaternary.active,
.button.quaternary:hover,
.button.quaternary:active,
.button.quaternary:focus,
.button.quaternary.active {
  background-color: #0298c8;
}
/* line 946, ../../../src/scss/screen.scss */
button.large,
.button.large {
  font-size: 20px;
  word-spacing: 1px;
  padding: 16px 30px;
}
/* line 953, ../../../src/scss/screen.scss */
button::-moz-focus-inner, button::-moz-focus-inner,
.button::-moz-focus-inner,
.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* line 963, ../../../src/scss/screen.scss */
fieldset {
  margin-top: 3em;
}
/* line 972, ../../../src/scss/screen.scss */
fieldset .field-error input[type="text"],
fieldset .field-error input[type="email"],
fieldset .field-error input[type="search"],
fieldset .field-error input[type="tel"],
fieldset .field-error textarea,
fieldset .field-error .custom-select {
  border: 1px solid #ee6557;
}
/* line 977, ../../../src/scss/screen.scss */
fieldset .error {
  color: #ee6557;
}

/* line 982, ../../../src/scss/screen.scss */
label {
  cursor: pointer;
}

/* line 992, ../../../src/scss/screen.scss */
input[type="text"],
input[type="email"],
input[type="search"],
input[type="tel"],
.placeholder-wrap,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  display: block;
  width: 100%;
  padding: 10px;
  outline: none;
  background-color: #fff;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* line 1006, ../../../src/scss/screen.scss */
textarea {
  max-width: 100%;
  min-height: 160px;
}

/* line 1012, ../../../src/scss/screen.scss */
::-webkit-input-placeholder {
  color: #aaaaaa;
}

/* line 1016, ../../../src/scss/screen.scss */
:-moz-placeholder {
  color: #aaaaaa;
}

/* line 1021, ../../../src/scss/screen.scss */
.placeholder-wrap {
  z-index: 1;
}

/* line 1027, ../../../src/scss/screen.scss */
.placeholder-wrap input,
.placeholder-wrap textarea {
  position: relative;
  z-index: 2;
  background: none;
  padding: 0;
}

/* line 1035, ../../../src/scss/screen.scss */
.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 40px;
  color: #aaaaaa;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 1049, ../../../src/scss/screen.scss */
.input-wrap {
  position: relative;
}
/* line 1053, ../../../src/scss/screen.scss */
.input-wrap button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 6px 0 7px;
  background: none;
  color: #d1d1d1;
}
/* line 1065, ../../../src/scss/screen.scss */
.input-wrap button:hover, .input-wrap button:active, .input-wrap button:focus {
  color: #16a6b6;
}

/* line 1073, ../../../src/scss/screen.scss */
.custom-radio,
.custom-checkbox {
  display: block;
  padding: 0;
  background-color: #d1d1d1;
  -webkit-transition: all 275ms;
  -moz-transition: all 275ms;
  -o-transition: all 275ms;
  transition: all 275ms;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  display: block;
  text-transform: uppercase;
  z-index: 1;
  padding: 8px 0;
}
/* line 1084, ../../../src/scss/screen.scss */
.custom-radio input,
.custom-checkbox input {
  display: none;
}

/* line 1092, ../../../src/scss/screen.scss */
.custom-radio .info,
.custom-checkbox .info,
.custom-select .info {
  display: none;
  margin-top: -5px;
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  -webkit-transition: all 275ms;
  -moz-transition: all 275ms;
  -o-transition: all 275ms;
  transition: all 275ms;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  font-size: 16px;
  word-spacing: 1px;
  line-height: 22px;
  font-family: "freightsans_regular", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  text-transform: none;
  letter-spacing: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 18px 16px 24px;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  -ms-border-radius: 0 0 5px 5px;
  -o-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  z-index: -9999;
  color: #fff;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
}
/* line 1116, ../../../src/scss/screen.scss */
.custom-radio .info.active,
.custom-checkbox .info.active,
.custom-select .info.active {
  pointer-events: auto;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  opacity: 0.9;
}
/* line 1122, ../../../src/scss/screen.scss */
.custom-radio .check,
.custom-checkbox .check,
.custom-select .check {
  position: absolute;
  font-size: 13px;
  bottom: 3px;
  right: 10px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 275ms;
  -moz-transition: all 275ms;
  -o-transition: all 275ms;
  transition: all 275ms;
}
/* line 1132, ../../../src/scss/screen.scss */
.custom-radio.active .check,
.custom-checkbox.active .check,
.custom-select.active .check {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* line 1143, ../../../src/scss/screen.scss */
.custom-radio:hover, .custom-radio:focus, .custom-radio:active, .custom-radio.active,
.custom-radio .info {
  background: #ee6557;
}

/* line 1153, ../../../src/scss/screen.scss */
.custom-checkbox:hover, .custom-checkbox:focus, .custom-checkbox:active, .custom-checkbox.active,
.custom-checkbox .info {
  background: #16a6b6;
}

/* line 1161, ../../../src/scss/screen.scss */
html.touch .custom-radio .info,
html.touch .custom-checkbox .info {
  display: none !important;
}
/* line 1167, ../../../src/scss/screen.scss */
html.touch .custom-radio:hover, html.touch .custom-radio:active, html.touch .custom-radio:focus,
html.touch .custom-checkbox:hover,
html.touch .custom-checkbox:active,
html.touch .custom-checkbox:focus {
  background: #d1d1d1;
}
/* line 1174, ../../../src/scss/screen.scss */
html.touch .custom-radio.active,
html.touch .custom-radio .info {
  background: #ee6557;
}
/* line 1181, ../../../src/scss/screen.scss */
html.touch .custom-checkbox.active,
html.touch .custom-checkbox .info {
  background: #16a6b6;
}

/* line 1187, ../../../src/scss/screen.scss */
.custom-select {
  cursor: pointer;
  display: block;
  position: relative;
  background-color: #fff;
  padding: 0 42px 0 16px;
  line-height: 40px;
  height: 40px;
  color: #aaaaaa;
  text-align: left;
  letter-spacing: 0;
  text-transform: none;
}
/* line 1200, ../../../src/scss/screen.scss */
.custom-select select {
  display: none;
}
/* line 1204, ../../../src/scss/screen.scss */
.custom-select i {
  -webkit-transition: color 275ms;
  -moz-transition: color 275ms;
  -o-transition: color 275ms;
  transition: color 275ms;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  float: right;
  color: #d1d1d1;
  position: absolute;
  right: 13px;
  top: 2px;
}
/* line 1214, ../../../src/scss/screen.scss */
.custom-select .info {
  background-color: #fff;
  padding: 8px;
}
/* line 1218, ../../../src/scss/screen.scss */
.custom-select .info a {
  border: none;
  color: #6a6a65;
  display: block;
  border-radius: 5px;
  line-height: 36px;
  padding: 0 8px;
}
/* line 1228, ../../../src/scss/screen.scss */
.custom-select .info a:hover, .custom-select .info a:active, .custom-select .info a:focus {
  background: whitesmoke;
}
/* line 1238, ../../../src/scss/screen.scss */
.custom-select:hover, .custom-select:focus, .custom-select:active, .custom-select.active,
.custom-select .info {
  background-color: #fff;
  color: #aaaaaa;
}
/* line 1242, ../../../src/scss/screen.scss */
.custom-select:hover i, .custom-select:focus i, .custom-select:active i, .custom-select.active i,
.custom-select .info i {
  color: #16a6b6;
}

/* line 1252, ../../../src/scss/screen.scss */
.circ {
  font-size: 30px;
  word-spacing: 1px;
  -webkit-border-radius: 1000px;
  -moz-border-radius: 1000px;
  -ms-border-radius: 1000px;
  -o-border-radius: 1000px;
  border-radius: 1000px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 105px;
  width: 100px;
  height: 100px;
  max-width: 100%;
  text-align: center;
  color: #fff;
  background-color: #16a6b6;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  position: relative;
}
/* line 1268, ../../../src/scss/screen.scss */
.circ.secondary {
  background-color: #ee6557;
}
/* line 1272, ../../../src/scss/screen.scss */
.circ.tertiary {
  background-color: #d1d1d1;
}
/* line 1276, ../../../src/scss/screen.scss */
.circ.large {
  font-size: 36px;
  word-spacing: 1px;
  line-height: 127px;
  width: 120px;
  height: 120px;
}

/* line 1287, ../../../src/scss/screen.scss */
.chart div,
.chart svg {
  position: absolute !important;
  top: 0;
  left: 0;
}
/* line 1293, ../../../src/scss/screen.scss */
.chart .h1 {
  margin: 0;
  height: 120px;
  width: 120px;
  line-height: 120px;
}
/* line 1308, ../../../src/scss/screen.scss */
.chart.grid-2 {
  width: 120px;
  margin-right: 14px;
  margin-left: 14px;
}

/* line 1317, ../../../src/scss/screen.scss */
.linegraph svg,
.linegraph div {
  margin: 0 auto;
}

/* line 1327, ../../../src/scss/screen.scss */
.slider {
  position: relative;
}
/* line 1331, ../../../src/scss/screen.scss */
.slider > li {
  display: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-font-smoothing: antialiased;
  top: 0;
  left: 0;
  width: 100%;
}
/* line 1339, ../../../src/scss/screen.scss */
.slider > li cite {
  font-weight: 400;
}
/* line 1343, ../../../src/scss/screen.scss */
.slider > li:first-child {
  display: block;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* line 1351, ../../../src/scss/screen.scss */
.slider-markers a {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  margin: 0 10px;
  display: block;
  background-color: #d1d1d1;
  border: none;
}
/* line 1365, ../../../src/scss/screen.scss */
.slider-markers a:hover, .slider-markers a:active, .slider-markers a:focus {
  border: none;
  background-color: #16a6b6;
}
/* line 1371, ../../../src/scss/screen.scss */
.slider-markers a.active {
  border: 3px solid #ee6557;
  background: none;
}

/* line 1378, ../../../src/scss/screen.scss */
.slider-regular li {
  background-color: whitesmoke;
}

/* line 1387, ../../../src/scss/screen.scss */
.m-content {
  display: none;
}

/* line 1391, ../../../src/scss/screen.scss */
#modal-wrap {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

/* line 1403, ../../../src/scss/screen.scss */
#modal-overlay {
  display: none;
  background-color: #000;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
}

/* line 1415, ../../../src/scss/screen.scss */
#modal {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  margin: 35px 0;
  vertical-align: middle;
  position: relative;
  text-align: left;
  max-width: 88%;
}
/* line 1423, ../../../src/scss/screen.scss */
#modal .tagline {
  color: #ee6557;
  margin-top: .3em;
}
/* line 1428, ../../../src/scss/screen.scss */
#modal .grid-7 {
  width: 50%;
  margin: 0;
}
/* line 1433, ../../../src/scss/screen.scss */
#modal .grid-14 {
  width: 100%;
}
/* line 1437, ../../../src/scss/screen.scss */
#modal .omega img {
  margin: 0 auto;
}
/* line 1444, ../../../src/scss/screen.scss */
#modal.wide-first .grid-7.alpha {
  width: 65%;
}
/* line 1448, ../../../src/scss/screen.scss */
#modal.wide-first .grid-7.omega {
  width: 35%;
}
/* line 1456, ../../../src/scss/screen.scss */
#modal.not-group #modal-left,
#modal.not-group #modal-right {
  display: none;
}
/* line 1462, ../../../src/scss/screen.scss */
#modal.no-footer #modal-footer {
  display: none;
}
/* line 1466, ../../../src/scss/screen.scss */
#modal.no-footer #modal-content {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
/* line 1474, ../../../src/scss/screen.scss */
#modal.footer-one-col #modal-footer .grid-7.alpha {
  width: 100%;
}
/* line 1478, ../../../src/scss/screen.scss */
#modal.footer-one-col #modal-footer .grid-7.omega {
  display: none;
}
/* line 1488, ../../../src/scss/screen.scss */
#modal.body-one-col #modal-content .grid-7.alpha {
  width: 100%;
}
/* line 1492, ../../../src/scss/screen.scss */
#modal.body-one-col #modal-content .grid-7.omega {
  display: none;
}
/* line 1500, ../../../src/scss/screen.scss */
#modal.footer-minimal #modal-content .grid-7 {
  padding-bottom: 0;
}
/* line 1504, ../../../src/scss/screen.scss */
#modal.footer-minimal #modal-footer {
  background: #fff;
}
/* line 1507, ../../../src/scss/screen.scss */
#modal.footer-minimal #modal-footer .grid-7 {
  padding-top: 0;
}

/* line 1515, ../../../src/scss/screen.scss */
#modal-content,
#modal-footer {
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  -o-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}
/* line 1519, ../../../src/scss/screen.scss */
#modal-content .grid-7,
#modal-content .grid-14,
#modal-footer .grid-7,
#modal-footer .grid-14 {
  padding: 45px 50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* line 1528, ../../../src/scss/screen.scss */
#modal-content .grid-7.omega,
#modal-footer .grid-7.omega {
  padding-left: 0;
}
/* line 1533, ../../../src/scss/screen.scss */
#modal-content .grid-14,
#modal-footer .grid-14 {
  padding-bottom: 0;
}

/* line 1538, ../../../src/scss/screen.scss */
#modal-content {
  background-color: #fff;
  position: relative;
  font-size: 20px;
  word-spacing: 1px;
  line-height: 30px;
}

/* line 1545, ../../../src/scss/screen.scss */
#modal-footer {
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  -ms-border-radius: 0 0 5px 5px;
  -o-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  background-color: whitesmoke;
}
/* line 1549, ../../../src/scss/screen.scss */
#modal-footer .grid-7.omega {
  text-align: right;
}

/* line 1556, ../../../src/scss/screen.scss */
#modal-left,
#modal-right,
#modal-close {
  display: block;
  position: absolute;
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  line-height: 50px;
  height: 50px;
  width: 50px;
  color: #d1d1d1;
  border: none;
  top: 50%;
  margin-top: -25px;
  text-align: center;
}
/* line 1572, ../../../src/scss/screen.scss */
#modal-left:hover, #modal-left:active, #modal-left:focus,
#modal-right:hover,
#modal-right:active,
#modal-right:focus,
#modal-close:hover,
#modal-close:active,
#modal-close:focus {
  color: #16a6b6;
}
/* line 1576, ../../../src/scss/screen.scss */
#modal-left i,
#modal-right i,
#modal-close i {
  line-height: 51px;
}

/* line 1581, ../../../src/scss/screen.scss */
#modal-left {
  left: -25px;
}

/* line 1585, ../../../src/scss/screen.scss */
#modal-right {
  right: -25px;
}

/* line 1589, ../../../src/scss/screen.scss */
#modal-close {
  top: 25px;
  right: 0;
  background: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

/* line 1596, ../../../src/scss/screen.scss */
.player-hex {
  margin-top: -35px !important;
}

/* line 1602, ../../../src/scss/screen.scss */
.button-list .button,
.button-list button {
  margin: 1em 10px 0;
}
/* line 1605, ../../../src/scss/screen.scss */
.button-list .button:first-child,
.button-list button:first-child {
  margin-left: 0;
}
/* line 1609, ../../../src/scss/screen.scss */
.button-list .button:last-child,
.button-list button:last-child {
  margin-right: 0;
}

/* line 1620, ../../../src/scss/screen.scss */
.heading-meta {
  font-size: 18px;
  word-spacing: 1px;
  line-height: 20px;
  color: #ee6557;
  margin-top: 5px;
}

/* line 1627, ../../../src/scss/screen.scss */
.heading-icon {
  margin-top: .55em;
  font-size: 60px;
  word-spacing: 1px;
  line-height: 60px;
  color: #16a6b6;
}
/* line 1633, ../../../src/scss/screen.scss */
.heading-icon.secondary {
  color: #ee6557;
}

/* line 1642, ../../../src/scss/screen.scss */
#map-find-us {
  height: 600px;
}

/* line 1646, ../../../src/scss/screen.scss */
.map {
  position: relative;
  margin-top: 1.6em;
  background-color: #DCDCDC;
}
/* line 1652, ../../../src/scss/screen.scss */
.map .map-fullscreen,
.map .zoomer {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  border: none;
  background-image: none;
  background: url('/assets/img/bg-white-85.png?1352212520');
  background: rgba(255, 255, 255, 0.8);
  text-indent: 0;
  font-family: "SSStandard";
  color: #ee6557;
  top: 2px;
  width: 50px;
  height: 50px;
  line-height: 54px;
  position: absolute;
  display: block;
  -webkit-transition: all 275ms;
  -moz-transition: all 275ms;
  -o-transition: all 275ms;
  transition: all 275ms;
  z-index: 100;
}
/* line 1672, ../../../src/scss/screen.scss */
.map .map-fullscreen:hover, .map .map-fullscreen:focus, .map .map-fullscreen:active,
.map .zoomer:hover,
.map .zoomer:focus,
.map .zoomer:active {
  background-color: #fff;
}
/* line 1676, ../../../src/scss/screen.scss */
.map .map-fullscreen:active,
.map .zoomer:active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
/* line 1681, ../../../src/scss/screen.scss */
.map .info {
  display: none;
  position: absolute;
  width: 30%;
  top: 30px;
  right: 30px;
  padding: 30px 50px 30px 30px;
  z-index: 100;
  text-align: left;
  background: url('/assets/img/bg-white-85.png?1352212520');
  background: rgba(255, 255, 255, 0.9);
}
/* line 1693, ../../../src/scss/screen.scss */
.map .info h4 {
  color: #ee6557;
}
/* line 1698, ../../../src/scss/screen.scss */
.map .zoomout {
  left: 2px;
}
/* line 1702, ../../../src/scss/screen.scss */
.map .zoomin {
  left: 54px;
}
/* line 1706, ../../../src/scss/screen.scss */
.map .map-fullscreen {
  left: 106px;
}
/* line 1709, ../../../src/scss/screen.scss */
.map .map-fullscreen:before {
  color: #16a6b6;
  content: '⤢';
}
/* line 1714, ../../../src/scss/screen.scss */
.map .map-fullscreen.active:before {
  content: '';
}
/* line 1719, ../../../src/scss/screen.scss */
.map .info-close {
  position: absolute;
  top: 12px;
  right: 12px;
  color: #d1d1d1;
  border: none;
}
/* line 1728, ../../../src/scss/screen.scss */
.map .info-close:hover, .map .info-close:active, .map .info-close:focus {
  color: #16a6b6;
}

/* line 1734, ../../../src/scss/screen.scss */
.map-meta {
  border: none;
  color: #d1d1d1;
  position: absolute;
  top: 42px;
  right: 12px;
}

/* line 1742, ../../../src/scss/screen.scss */
.map-lower {
  margin-top: 0;
  background-color: #fff;
  text-align: left;
  font-size: 20px;
  line-height: 30px;
}
/* line 1749, ../../../src/scss/screen.scss */
.map-lower > li {
  padding: 50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* line 1754, ../../../src/scss/screen.scss */
.map-lower ul {
  margin-top: 1.5em;
}

/* line 1760, ../../../src/scss/screen.scss */
.directions > li:before {
  content: '';
  font-family: "SSStandard";
  color: #ee6557;
  vertical-align: middle;
  padding-right: 20px;
}

/* line 1770, ../../../src/scss/screen.scss */
.map-fullscreen-map {
  position: fixed!important;
  width: auto!important;
  height: auto!important;
  top: 0!important;
  left: 0!important;
  right: 0!important;
  bottom: 0!important;
  z-index: 99999999999;
  margin: 0!important;
}
/* line 1781, ../../../src/scss/screen.scss */
.map-fullscreen-map.map-fullscreen {
  background-position: -120px 0;
}

/* line 1787, ../../../src/scss/screen.scss */
.zoombox-box,
.boxselector-box {
  margin: 0;
  padding: 0;
  border: 1px dashed #ee6557;
  background: url('/assets/img/bg-orange-30.png?1352212678');
  background: rgba(238, 101, 87, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  display: none;
}

/* line 1801, ../../../src/scss/screen.scss */
.marker-image {
  width: 34px;
  height: 50px;
  margin: -50px 0 0 -17px;
  cursor: pointer;
  pointer-events: all;
  position: absolute;
  z-index: 1;
}

/* line 1816, ../../../src/scss/screen.scss */
.marker-image.active {
  opacity: 1 !important;
}

/* line 1826, ../../../src/scss/screen.scss */
#header {
  margin-top: 0;
  margin-bottom: -50px;
  padding: 0;
}

/* line 1834, ../../../src/scss/screen.scss */
#header h2,
#ie6 h2 {
  margin-top: 1.5em;
  font-family: "freightsans_regular", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  text-transform: none;
  letter-spacing: 0;
  line-height: 32px;
  color: #6b6b6b;
}

/* line 1841, ../../../src/scss/screen.scss */
#nav {
  margin-top: 20px;
  margin-bottom: 55px;
  text-align: center;
}
/* line 1846, ../../../src/scss/screen.scss */
#nav a {
  border-color: #6a6a65;
  padding-bottom: 5px;
  display: block;
}
/* line 1854, ../../../src/scss/screen.scss */
#nav a:hover, #nav a:active, #nav a:focus {
  border-color: #16a6b6;
}
/* line 1860, ../../../src/scss/screen.scss */
#nav a.active {
  color: #ee6557;
  border-color: #ee6557;
}
/* line 1866, ../../../src/scss/screen.scss */
#nav p {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  vertical-align: middle;
  width: 120px;
  margin: 0 10px;
}
/* line 1872, ../../../src/scss/screen.scss */
#nav p.alpha {
  margin-left: 0;
}
/* line 1876, ../../../src/scss/screen.scss */
#nav p.omega {
  margin-right: 0;
}

/* line 1892, ../../../src/scss/screen.scss */
.logo {
  margin-top: -25px;
  text-indent: -999em;
}
/* line 1896, ../../../src/scss/screen.scss */
.logo a {
  padding: 0;
  border: none;
}
/* line 1900, ../../../src/scss/screen.scss */
.logo a img {
  display: block;
  height: auto;
}

/* line 1908, ../../../src/scss/screen.scss */

/* line 1918, ../../../src/scss/screen.scss */
#tagline {
  font-size: 84px;
  word-spacing: 1px;
  line-height: 90px;
  font-family: "neuzeitgrobold", sans-serif;
  text-transform: none;
  letter-spacing: -3px;
  color: #181818;
  text-align: center;
  margin: .95em 0 0;
}

/* line 1931, ../../../src/scss/screen.scss */
#footer {
  margin-top: 180px;
  margin-bottom: 0;
}
/* line 1935, ../../../src/scss/screen.scss */
#footer .list-icon-items > li {
  float: left;
}
/* line 1938, ../../../src/scss/screen.scss */
#footer .list-icon-items > li .has-border {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  border-bottom: 1px solid #16a6b6;
}
/* line 1944, ../../../src/scss/screen.scss */
#footer footer {
  padding-top: 40px;
}

/* line 1949, ../../../src/scss/screen.scss */
.icon-box {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  font-size: 18px;
  word-spacing: 1px;
  background-color: #d1d1d1;
  color: #fff;
  line-height: 52px;
  width: 50px;
  height: 50px;
  border: none;
  display: block;
  text-align: center;
}
/* line 1963, ../../../src/scss/screen.scss */
.icon-box:hover, .icon-box:active, .icon-box:focus {
  color: #fff;
  background-color: #16a6b6;
}

/* line 1969, ../../../src/scss/screen.scss */
#to-top {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  z-index: 9997;
  position: fixed;
  bottom: 25%;
  right: 10px;
  display: none;
}

/* line 1983, ../../../src/scss/screen.scss */
#work-projects {
  text-align: left;
  margin-top: 4%;
  position: relative;
}
/* line 1989, ../../../src/scss/screen.scss */
#work-projects .container .grid-12 {
  width: 70%;
  margin-right: 2%;
}
/* line 1994, ../../../src/scss/screen.scss */
#work-projects .container .grid-4 {
  float: right;
  width: 26%;
  margin-left: 2%;
}
/* line 2001, ../../../src/scss/screen.scss */
#work-projects h2 {
  font-family: "neuzeitgrobold", sans-serif;
  text-transform: none;
  letter-spacing: -3px;
  font-size: 36px;
  word-spacing: 1px;
  line-height: 42px;
  letter-spacing: -1px;
}
/* line 2008, ../../../src/scss/screen.scss */
#work-projects .inner {
  margin-top: 1em;
}
/* line 2012, ../../../src/scss/screen.scss */
#work-projects .icon-box {
  -webkit-transition: background-color 275ms;
  -moz-transition: background-color 275ms;
  -o-transition: background-color 275ms;
  transition: background-color 275ms;
  position: absolute;
  top: 275px;
  z-index: 1;
}
/* line 2018, ../../../src/scss/screen.scss */
#work-projects .icon-box.left {
  left: -10px;
}
/* line 2022, ../../../src/scss/screen.scss */
#work-projects .icon-box.right {
  right: -10px;
}
/* line 2028, ../../../src/scss/screen.scss */
#work-projects.fixed-arrows .icon-box {
  position: fixed;
  top: 50%;
}
/* line 2032, ../../../src/scss/screen.scss */
#work-projects.fixed-arrows .icon-box.left {
  left: 10px;
}
/* line 2036, ../../../src/scss/screen.scss */
#work-projects.fixed-arrows .icon-box.right {
  right: 10px;
}
/* line 2042, ../../../src/scss/screen.scss */
#work-projects.slider-loaded .slider-inner {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* line 2047, ../../../src/scss/screen.scss */
.project {
  top: 0;
  left: 0;
  width: 100%;
}

/* line 2053, ../../../src/scss/screen.scss */
.project-header {
  position: relative;
}
/* line 2056, ../../../src/scss/screen.scss */
.project-header .grid-12 {
  position: relative;
}
/* line 2060, ../../../src/scss/screen.scss */
.project-header .imac {
  width: 100%;
  -webkit-backface-visibility: hidden;
}

/* line 2067, ../../../src/scss/screen.scss */
.tags a, .tags .link-like {
  display: block;
  border: none;
  color: #ee6557;
  cursor: pointer;
  -webkit-transition: color 275ms;
  -moz-transition: color 275ms;
  -o-transition: color 275ms;
  transition: color 275ms;
}
/* line 2077, ../../../src/scss/screen.scss */
.tags a:hover, .tags a:focus, .tags a:active, .tags a.active, .tags .link-like:hover, .tags .link-like:focus, .tags .link-like:active, .tags .link-like.active {
  color: #cb2514;
}

/* line 2083, ../../../src/scss/screen.scss */
.slider-wrap {
  position: relative;
  /*margin-bottom: 100px;*/
}

/* line 2088, ../../../src/scss/screen.scss */
.slider-inner {
  position: relative;
  filter: inherit;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
}

/* line 2094, ../../../src/scss/screen.scss */
.project-slider {
  margin: 0;
  position: absolute;
  top: 5.1%;
  left: 3.9%;
  right: 3.9%;
  bottom: 26.2%;
  height: auto;
  width: auto;
  overflow: hidden;
}
/* line 2105, ../../../src/scss/screen.scss */
.project-slider li {
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
}
/* line 2117, ../../../src/scss/screen.scss */
.project-slider img {
  width: 100%;
}

@media screen and (max-width: 1290px) {
  /* line 2128, ../../../src/scss/screen.scss */
  #work-projects .container {
    width: 89%;
  }
}
/* line 2140, ../../../src/scss/screen.scss */
html body .mq-mid,
html body .mq-small,
html body .mq-mobile,
html body .mq-mid-i-block,
html body .mq-small-i-block,
html body .mq-mobile-i-block {
  display: none !important;
}
/* line 2144, ../../../src/scss/screen.scss */
html body .mq-wide {
  display: block !important;
}
/* line 2148, ../../../src/scss/screen.scss */
html body .mq-wide-i-block {
  display: inline-block !important;
  *display: inline !important;
}

@media screen and (max-width: 1180px) {
  /* line 2167, ../../../src/scss/screen.scss */
  html body .mq-wide,
  html body .mq-small,
  html body .mq-mobile,
  html body .mq-wide-i-block,
  html body .mq-small-i-block,
  html body .mq-mobile-i-block {
    display: none !important;
  }
  /* line 2171, ../../../src/scss/screen.scss */
  html body .mq-mid {
    display: block !important;
  }
  /* line 2175, ../../../src/scss/screen.scss */
  html body .mq-mid-i-block {
    display: inline-block !important;
    *display: inline !important;
  }

  /* line 2181, ../../../src/scss/screen.scss */
  .container {
    width: 960px;
  }

  /* line 2185, ../../../src/scss/screen.scss */
  #nav p {
    width: 108px;
  }

  /* line 2190, ../../../src/scss/screen.scss */
  .list-hex-grid.large div.hex {
    margin-top: 37px;
    margin-bottom: 37px;
    width: 220px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  /* line 162, ../../../src/scss/_utils.scss */
  .list-hex-grid.large div.hex > a,
  .list-hex-grid.large div.hex > div {
    height: 127px;
    line-height: 254px;
  }
  /* line 168, ../../../src/scss/_utils.scss */
  .list-hex-grid.large div.hex .inner {
    top: -63.5px;
  }
  /* line 173, ../../../src/scss/_utils.scss */
  .list-hex-grid.large div.hex .hex-1:before,
  .list-hex-grid.large div.hex .hex-2:before {
    height: 254px;
    width: 254px;
  }
  /* line 178, ../../../src/scss/_utils.scss */
  .list-hex-grid.large div.hex .hex-1:before {
    -webkit-transform: rotate(-60deg) translate(-127px, 0);
    -moz-transform: rotate(-60deg) translate(-127px, 0);
    -ms-transform: rotate(-60deg) translate(-127px, 0);
    -o-transform: rotate(-60deg) translate(-127px, 0);
    transform: rotate(-60deg) translate(-127px, 0);
  }
  /* line 182, ../../../src/scss/_utils.scss */
  .list-hex-grid.large div.hex .hex-2:before {
    -webkit-transform: rotate(60deg) translate(47px, 47px);
    -moz-transform: rotate(60deg) translate(47px, 47px);
    -ms-transform: rotate(60deg) translate(47px, 47px);
    -o-transform: rotate(60deg) translate(47px, 47px);
    transform: rotate(60deg) translate(47px, 47px);
  }
  /* line 2197, ../../../src/scss/screen.scss */
  .list-hex-grid.large div.hex > a,
  .list-hex-grid.large div.hex > div {
    font-size: 15px;
    -webkit-background-size: auto 260px;
    -moz-background-size: auto 260px;
    -o-background-size: auto 260px;
    background-size: auto 260px;
  }
  /* line 2203, ../../../src/scss/screen.scss */
  .list-hex-grid.small div.hex {
    margin-top: 37px;
    margin-bottom: 37px;
    width: 172px;
    margin-top: 33px;
    margin-bottom: 33px;
  }
  /* line 162, ../../../src/scss/_utils.scss */
  .list-hex-grid.small div.hex > a,
  .list-hex-grid.small div.hex > div {
    height: 99px;
    line-height: 198px;
  }
  /* line 168, ../../../src/scss/_utils.scss */
  .list-hex-grid.small div.hex .inner {
    top: -49.5px;
  }
  /* line 173, ../../../src/scss/_utils.scss */
  .list-hex-grid.small div.hex .hex-1:before,
  .list-hex-grid.small div.hex .hex-2:before {
    height: 198px;
    width: 198px;
  }
  /* line 178, ../../../src/scss/_utils.scss */
  .list-hex-grid.small div.hex .hex-1:before {
    -webkit-transform: rotate(-60deg) translate(-99px, 0);
    -moz-transform: rotate(-60deg) translate(-99px, 0);
    -ms-transform: rotate(-60deg) translate(-99px, 0);
    -o-transform: rotate(-60deg) translate(-99px, 0);
    transform: rotate(-60deg) translate(-99px, 0);
  }
  /* line 182, ../../../src/scss/_utils.scss */
  .list-hex-grid.small div.hex .hex-2:before {
    -webkit-transform: rotate(60deg) translate(36px, 36px);
    -moz-transform: rotate(60deg) translate(36px, 36px);
    -ms-transform: rotate(60deg) translate(36px, 36px);
    -o-transform: rotate(60deg) translate(36px, 36px);
    transform: rotate(60deg) translate(36px, 36px);
  }

  /* line 2212, ../../../src/scss/screen.scss */
  .list-blocks > li.grid-7 {
    width: 440px;
  }

  /* line 2216, ../../../src/scss/screen.scss */
  #footer {
    margin-top: 150px;
  }
}
@media screen and (max-width: 1050px) {
  /* line 2224, ../../../src/scss/screen.scss */
  #work-projects {
    font-size: 20px;
    line-height: 30px;
  }
  /* line 2230, ../../../src/scss/screen.scss */
  #work-projects .container .grid-12,
  #work-projects .container .grid-4 {
    display: block;
    float: none;
    text-align: center;
  }
  /* line 2236, ../../../src/scss/screen.scss */
  #work-projects .container .grid-4 {
    width: 100%;
    margin: 0;
  }
  /* line 2241, ../../../src/scss/screen.scss */
  #work-projects .container .grid-12 {
    width: 90%;
    width: 90%;
    margin: 80px auto 0;
  }
  /* line 2248, ../../../src/scss/screen.scss */
  #work-projects h2 {
    font-size: 60px;
    line-height: 70px;
  }
  /* line 2253, ../../../src/scss/screen.scss */
  #work-projects .inner {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
  /* line 2259, ../../../src/scss/screen.scss */
  #work-projects .inner p:first-of-type {
    font-size: 24px;
    line-height: 32px;
  }

  /* line 2266, ../../../src/scss/screen.scss */
  .map .info {
    width: 45%;
  }
}
@media screen and (max-width: 1016px) {
  /* line 2281, ../../../src/scss/screen.scss */
  html body .mq-wide,
  html body .mq-mid,
  html body .mq-mobile,
  html body .mq-wide-i-block,
  html body .mq-mid-i-block,
  html body .mq-mobile-i-block {
    display: none !important;
  }
  /* line 2285, ../../../src/scss/screen.scss */
  html body .mq-small {
    display: block !important;
  }
  /* line 2289, ../../../src/scss/screen.scss */
  html body .mq-small-i-block {
    display: inline-block !important;
    *display: inline !important;
  }

  /* line 51, ../../../src/scss/_utils.scss */
  .container {
    width: 984px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-1 {
    width: 41.5px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-1.large {
    width: 51.875px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-2 {
    width: 103px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-2.large {
    width: 128.75px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-3 {
    width: 164.5px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-3.large {
    width: 205.625px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-4 {
    width: 226px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-4.large {
    width: 282.5px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-5 {
    width: 287.5px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-5.large {
    width: 359.375px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-6 {
    width: 349px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-6.large {
    width: 436.25px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-7 {
    width: 410.5px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-7.large {
    width: 513.125px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-8 {
    width: 472px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-8.large {
    width: 590px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-9 {
    width: 533.5px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-9.large {
    width: 666.875px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-10 {
    width: 595px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-10.large {
    width: 743.75px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-11 {
    width: 656.5px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-11.large {
    width: 820.625px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-12 {
    width: 718px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-12.large {
    width: 897.5px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-13 {
    width: 779.5px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-13.large {
    width: 974.375px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-14 {
    width: 841px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-14.large {
    width: 1051.25px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-15 {
    width: 902.5px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-15.large {
    width: 1128.125px;
  }

  /* line 62, ../../../src/scss/_utils.scss */
  .grid-16 {
    width: 964px;
  }
  /* line 65, ../../../src/scss/_utils.scss */
  .grid-16.large {
    width: 1205px;
  }

  /* line 2297, ../../../src/scss/screen.scss */
  .container {
    width: 840px;
  }

  /* line 2301, ../../../src/scss/screen.scss */
  #nav p {
    width: 87px;
  }
  /* line 2304, ../../../src/scss/screen.scss */
  #nav p.logo {
    width: 100px;
  }

  /* line 2310, ../../../src/scss/screen.scss */
  .list-hex-grid.large div.hex,
  .list-hex-grid div.hex {
    margin-top: 37px;
    margin-bottom: 37px;
    width: 190px;
    margin-top: 37px;
    margin-bottom: 37px;
  }
  /* line 162, ../../../src/scss/_utils.scss */
  .list-hex-grid.large div.hex > a,
  .list-hex-grid.large div.hex > div,
  .list-hex-grid div.hex > a,
  .list-hex-grid div.hex > div {
    height: 110px;
    line-height: 220px;
  }
  /* line 168, ../../../src/scss/_utils.scss */
  .list-hex-grid.large div.hex .inner,
  .list-hex-grid div.hex .inner {
    top: -55px;
  }
  /* line 173, ../../../src/scss/_utils.scss */
  .list-hex-grid.large div.hex .hex-1:before,
  .list-hex-grid.large div.hex .hex-2:before,
  .list-hex-grid div.hex .hex-1:before,
  .list-hex-grid div.hex .hex-2:before {
    height: 220px;
    width: 220px;
  }
  /* line 178, ../../../src/scss/_utils.scss */
  .list-hex-grid.large div.hex .hex-1:before,
  .list-hex-grid div.hex .hex-1:before {
    -webkit-transform: rotate(-60deg) translate(-110px, 0);
    -moz-transform: rotate(-60deg) translate(-110px, 0);
    -ms-transform: rotate(-60deg) translate(-110px, 0);
    -o-transform: rotate(-60deg) translate(-110px, 0);
    transform: rotate(-60deg) translate(-110px, 0);
  }
  /* line 182, ../../../src/scss/_utils.scss */
  .list-hex-grid.large div.hex .hex-2:before,
  .list-hex-grid div.hex .hex-2:before {
    -webkit-transform: rotate(60deg) translate(40px, 40px);
    -moz-transform: rotate(60deg) translate(40px, 40px);
    -ms-transform: rotate(60deg) translate(40px, 40px);
    -o-transform: rotate(60deg) translate(40px, 40px);
    transform: rotate(60deg) translate(40px, 40px);
  }
  /* line 2317, ../../../src/scss/screen.scss */
  .list-hex-grid.large div.hex > a,
  .list-hex-grid.large div.hex > div,
  .list-hex-grid div.hex > a,
  .list-hex-grid div.hex > div {
    font-size: 12px;
    -webkit-background-size: auto 220px;
    -moz-background-size: auto 220px;
    -o-background-size: auto 220px;
    background-size: auto 220px;
  }

  /* line 2323, ../../../src/scss/screen.scss */
  .list-hex-grid.small div.hex {
    margin-top: 37px;
    margin-bottom: 37px;
    width: 148px;
    margin-top: 33px;
    margin-bottom: 33px;
  }
  /* line 162, ../../../src/scss/_utils.scss */
  .list-hex-grid.small div.hex > a,
  .list-hex-grid.small div.hex > div {
    height: 85px;
    line-height: 170px;
  }
  /* line 168, ../../../src/scss/_utils.scss */
  .list-hex-grid.small div.hex .inner {
    top: -42.5px;
  }
  /* line 173, ../../../src/scss/_utils.scss */
  .list-hex-grid.small div.hex .hex-1:before,
  .list-hex-grid.small div.hex .hex-2:before {
    height: 170px;
    width: 170px;
  }
  /* line 178, ../../../src/scss/_utils.scss */
  .list-hex-grid.small div.hex .hex-1:before {
    -webkit-transform: rotate(-60deg) translate(-85px, 0);
    -moz-transform: rotate(-60deg) translate(-85px, 0);
    -ms-transform: rotate(-60deg) translate(-85px, 0);
    -o-transform: rotate(-60deg) translate(-85px, 0);
    transform: rotate(-60deg) translate(-85px, 0);
  }
  /* line 182, ../../../src/scss/_utils.scss */
  .list-hex-grid.small div.hex .hex-2:before {
    -webkit-transform: rotate(60deg) translate(31px, 31px);
    -moz-transform: rotate(60deg) translate(31px, 31px);
    -ms-transform: rotate(60deg) translate(31px, 31px);
    -o-transform: rotate(60deg) translate(31px, 31px);
    transform: rotate(60deg) translate(31px, 31px);
  }

  /* line 2331, ../../../src/scss/screen.scss */
  .list-blocks div.hex.grid-7 {
    width: 380px;
  }

  /* line 2335, ../../../src/scss/screen.scss */
  #footer {
    margin-top: 120px;
  }
}
@media screen and (max-width: 898px) {
  /* line 86, ../../../src/scss/_utils.scss */
  .container {
    width: 90%;
  }

  /* line 97, ../../../src/scss/_utils.scss */
  .grid-1, .grid-2,
  .grid-3, .grid-4,
  .grid-5, .grid-6,
  .grid-7, .grid-8,
  .grid-9, .grid-10,
  .grid-11, .grid-12,
  .grid-13, .grid-14,
  .grid-15, .grid-16 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  /* line 102, ../../../src/scss/_utils.scss */
  .grid-1.large, .grid-2.large,
  .grid-3.large, .grid-4.large,
  .grid-5.large, .grid-6.large,
  .grid-7.large, .grid-8.large,
  .grid-9.large, .grid-10.large,
  .grid-11.large, .grid-12.large,
  .grid-13.large, .grid-14.large,
  .grid-15.large, .grid-16.large {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  /* line 2351, ../../../src/scss/screen.scss */
  html body .mq-wide,
  html body .mq-mid,
  html body .mq-small,
  html body .mq-wide-i-block,
  html body .mq-mid-i-block,
  html body .mq-small-i-block {
    display: none !important;
  }
  /* line 2355, ../../../src/scss/screen.scss */
  html body .mq-mobile {
    display: block !important;
  }
  /* line 2359, ../../../src/scss/screen.scss */
  html body .mq-mobile-i-block {
    display: inline-block !important;
    *display: inline !important;
  }

  /* line 2365, ../../../src/scss/screen.scss */
  .section {
    margin-top: 115px;
  }
  /* line 2368, ../../../src/scss/screen.scss */
  .section.slim {
    margin-top: 75px;
  }

  /* line 2374, ../../../src/scss/screen.scss */
  hr.center.grid-2 {
    width: 120px;
  }

  /* line 2380, ../../../src/scss/screen.scss */
  .list-hex-grid > br {
    display: none !important;
  }
  /* line 2388, ../../../src/scss/screen.scss */
  .list-hex-grid div.hex .hex-1,
  .list-hex-grid div.hex .hex-2,
  .list-hex-grid div.hex .hex-1:before,
  .list-hex-grid div.hex .hex-2:before {
    display: none !important;
  }
  /* line 2393, ../../../src/scss/screen.scss */
  .list-hex-grid div.hex > a,
  .list-hex-grid div.hex > div {
    padding: 50px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-backface-visibility: visible;
  }
  /* line 2399, ../../../src/scss/screen.scss */
  .list-hex-grid div.hex.inline-bg {
    overflow: hidden;
  }
  /* line 2403, ../../../src/scss/screen.scss */
  .list-hex-grid div.hex.inline-bg > a,
  .list-hex-grid div.hex.inline-bg > div {
    overflow: hidden;
  }
  /* line 2407, ../../../src/scss/screen.scss */
  .list-hex-grid div.hex.inline-bg img {
    top: 9%;
    left: 12%;
    max-width: 75%;
  }
  /* line 2418, ../../../src/scss/screen.scss */
  .list-hex-grid.mobile-centred-imgs > li > a,
  .list-hex-grid.mobile-centred-imgs > li > div {
    padding: 0;
    height: 210px;
  }
  /* line 2424, ../../../src/scss/screen.scss */
  .list-hex-grid.mobile-centred-imgs img {
    vertical-align: middle;
  }

  /* line 2442, ../../../src/scss/screen.scss */
  .list-hex-grid div.hex,
  .list-hex-grid.large div.hex,
  .list-hex-grid.mid div.hex,
  .list-hex-grid.small div.hex {
    width: 46.5%;
    margin: 1.75%;
  }
  /* line 2448, ../../../src/scss/screen.scss */
  .list-hex-grid div.hex > a,
  .list-hex-grid div.hex > div,
  .list-hex-grid.large div.hex > a,
  .list-hex-grid.large div.hex > div,
  .list-hex-grid.mid div.hex > a,
  .list-hex-grid.mid div.hex > div,
  .list-hex-grid.small div.hex > a,
  .list-hex-grid.small div.hex > div {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 50px 8px;
  }
  /* line 2452, ../../../src/scss/screen.scss */
  .list-hex-grid div.hex > a.flex,
  .list-hex-grid div.hex > div.flex,
  .list-hex-grid.large div.hex > a.flex,
  .list-hex-grid.large div.hex > div.flex,
  .list-hex-grid.mid div.hex > a.flex,
  .list-hex-grid.mid div.hex > div.flex,
  .list-hex-grid.small div.hex > a.flex,
  .list-hex-grid.small div.hex > div.flex {
    font-size: 14px;
  }

  /* line 2459, ../../../src/scss/screen.scss */
  .list-icon-items {
    margin-top: 0;
  }
  /* line 2464, ../../../src/scss/screen.scss */
  .list-icon-items.large-margins > li, .list-icon-items.no-margins > li,
  .list-icon-items > li {
    width: 46.5%;
    margin: 40px 1.75% 0;
  }
  /* line 2469, ../../../src/scss/screen.scss */
  .list-icon-items .button {
    display: none;
  }

  /* line 2474, ../../../src/scss/screen.scss */
  #footer .list-icon-items > li {
    width: 100%;
    margin: 0;
  }

  /* line 2480, ../../../src/scss/screen.scss */
  .list-blocks.instagram > li {
    width: auto;
  }

  /* line 2485, ../../../src/scss/screen.scss */
  .subsection {
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid #6a6a65;
  }

  /* line 2491, ../../../src/scss/screen.scss */
  .form-el {
    margin-top: 1em;
  }
  /* line 2494, ../../../src/scss/screen.scss */
  .form-el:first-child {
    margin-top: 0;
  }

  /* line 2499, ../../../src/scss/screen.scss */
  .list-mobile-spacing > li {
    margin-top: 40px;
    margin-bottom: 0;
  }

  /* line 2504, ../../../src/scss/screen.scss */
  #nav {
    width: 300px;
  }
  /* line 2507, ../../../src/scss/screen.scss */
  #nav p {
    width: auto;
    font-size: 20px;
    word-spacing: 1px;
    line-height: 32px;
  }
  /* line 2513, ../../../src/scss/screen.scss */
  #nav p.text-left, #nav p.text-right {
    text-align: center;
  }
  /* line 2518, ../../../src/scss/screen.scss */
  #nav p.logo {
    margin-bottom: 15px;
    width: 120px;
  }
  /* line 2526, ../../../src/scss/screen.scss */
  #nav p,
  #nav p.alpha,
  #nav p.omega {
    margin: 0;
  }
  /* line 2530, ../../../src/scss/screen.scss */
  #nav a {
    padding: 0 10px;
    border: none;
  }

  /* line 2536, ../../../src/scss/screen.scss */
  #footer {
    width: 88%;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  /* line 2542, ../../../src/scss/screen.scss */
  #footer .input-wrap {
    width: 75%;
    margin-right: auto;
    margin-left: auto;
  }

  /* line 2549, ../../../src/scss/screen.scss */
  #tagline {
    font-size: 60px;
    word-spacing: 1px;
    line-height: 70px;
    margin-top: .3em;
  }

  /* line 2555, ../../../src/scss/screen.scss */
  .list-blocks > li.grid-7 {
    width: 100%;
  }

  /* line 2559, ../../../src/scss/screen.scss */
  .list-blocks.instagram > li {
    max-width: 100%;
    height: auto;
    min-height: 200px;
  }

  /* line 2565, ../../../src/scss/screen.scss */
  #modal {
    width: 84%;
  }
  /* line 2568, ../../../src/scss/screen.scss */
  #modal .grid-7 {
    width: 100%;
    margin: 0;
  }
  /* line 2575, ../../../src/scss/screen.scss */
  #modal.wide-first .grid-7.alpha {
    width: 100%;
  }
  /* line 2579, ../../../src/scss/screen.scss */
  #modal.wide-first .grid-7.omega {
    width: 100%;
  }
  /* line 2584, ../../../src/scss/screen.scss */
  #modal.wide-first .player-hex {
    width: 65%;
    margin: 0 auto;
    padding: 30px 0 20px;
  }
  /* line 2590, ../../../src/scss/screen.scss */
  #modal.wide-first div.hex {
    width: 100%;
  }
  /* line 2594, ../../../src/scss/screen.scss */
  #modal.wide-first div.hex > a,
  #modal.wide-first div.hex > div {
    padding: 90px 0;
  }

  /* line 2604, ../../../src/scss/screen.scss */
  #modal-content .grid-7.alpha,
  #modal-content .grid-7.omega,
  #modal-footer .grid-7.alpha,
  #modal-footer .grid-7.omega {
    padding: 35px 40px;
  }
  /* line 2610, ../../../src/scss/screen.scss */
  #modal-content .grid-14.alpha,
  #modal-content .grid-14.omega,
  #modal-footer .grid-14.alpha,
  #modal-footer .grid-14.omega {
    padding: 35px 40px 0;
  }

  /* line 2616, ../../../src/scss/screen.scss */
  #modal-footer .grid-7.omega {
    display: none;
  }

  /* line 2621, ../../../src/scss/screen.scss */
  #footer {
    margin-top: 100px;
  }

  /* line 2625, ../../../src/scss/screen.scss */
  .with-labels .below {
    display: none;
  }

  /* line 2629, ../../../src/scss/screen.scss */
  #work-projects {
    margin-top: 50px;
  }

  /* line 2634, ../../../src/scss/screen.scss */
  .map-lower .grid-9 {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 750px) {
  /* line 2641, ../../../src/scss/screen.scss */
  #map-find-us {
    height: 450px;
  }

  /* line 2646, ../../../src/scss/screen.scss */
  .map .info {
    top: auto;
    bottom: 20px;
    right: 20px;
    width: 55%;
  }
}
@media screen and (max-width: 540px) {
  /* line 2657, ../../../src/scss/screen.scss */
  #page-border {
    margin: 14px;
  }

  /* line 2661, ../../../src/scss/screen.scss */
  .container {
    width: 92%;
  }

  /* line 2673, ../../../src/scss/screen.scss */
  .list-icon-items > li,
  .list-icon-items div.hex,
  .list-icon-items.large-margins > li,
  .list-icon-items.large-margins div.hex,
  .list-icon-items.no-margins > li,
  .list-icon-items.no-margins div.hex,
  .list-hex-grid > li,
  .list-hex-grid div.hex,
  .list-hex-grid.large > li,
  .list-hex-grid.large div.hex,
  .list-hex-grid.mid > li,
  .list-hex-grid.mid div.hex,
  .list-hex-grid.small > li,
  .list-hex-grid.small div.hex {
    width: 100%;
    margin: 40px 0 0;
    display: block;
    float: none;
  }

  /* line 2692, ../../../src/scss/screen.scss */
  #to-top {
    bottom: 15%;
    right: 5px;
  }

  /* line 2697, ../../../src/scss/screen.scss */
  #modal {
    width: 88%;
  }

  /* line 2702, ../../../src/scss/screen.scss */
  #modal-left,
  #modal-right {
    display: none;
  }

  /* line 2706, ../../../src/scss/screen.scss */
  #footer {
    margin-top: 80px;
  }
  /* line 2709, ../../../src/scss/screen.scss */
  #footer .input-wrap {
    width: 100%;
  }

  /* line 2714, ../../../src/scss/screen.scss */
  #work-projects {
    font-size: 18px;
    line-height: 24px;
  }
  /* line 2719, ../../../src/scss/screen.scss */
  #work-projects .container .grid-12 {
    margin-top: 70px;
  }
  /* line 2724, ../../../src/scss/screen.scss */
  #work-projects .inner {
    width: 84%;
  }
  /* line 2727, ../../../src/scss/screen.scss */
  #work-projects .inner p:first-child {
    font-size: 22px;
    line-height: 30px;
  }
  /* line 2733, ../../../src/scss/screen.scss */
  #work-projects .gwrap {
    margin-bottom: 60px;
  }

  /* line 2738, ../../../src/scss/screen.scss */
  .icon-box {
    font-size: 16px;
    word-spacing: 1px;
    line-height: 38px;
    width: 40px;
    height: 40px;
  }

  /* line 2745, ../../../src/scss/screen.scss */
  #map-find-us {
    height: 450px;
  }

  /* line 2750, ../../../src/scss/screen.scss */
  .map .info {
    width: auto;
    bottom: 20px;
    right: 20px;
    left: 20px;
  }
}
@media screen and (max-width: 360px) {
  /* line 2761, ../../../src/scss/screen.scss */
  html {
    word-wrap: break-word;
    word-break: hyphenate !important;
  }

  /* line 2766, ../../../src/scss/screen.scss */
  #nav {
    width: 120%;
    margin-left: -10%;
  }

  /* line 2771, ../../../src/scss/screen.scss */
  #footer {
    margin-top: 50px;
  }

  /* line 2775, ../../../src/scss/screen.scss */
  #map-find-us {
    height: 400px;
  }

  /* line 2780, ../../../src/scss/screen.scss */
  .map .info {
    width: auto;
    bottom: 2px;
    right: 2px;
    left: 2px;
  }
}
/* line 2794, ../../../src/scss/screen.scss */
.display-inline {
  display: inline !important;
}

/* line 2798, ../../../src/scss/screen.scss */
.display-inline-block {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  display: inline-block !important;
  *display: inline !important;
}

/* line 2804, ../../../src/scss/screen.scss */
.display-block {
  display: block !important;
}

/* line 2808, ../../../src/scss/screen.scss */
.display-none {
  display: none !important;
}

/* line 2813, ../../../src/scss/screen.scss */
.float-left {
  float: left !important;
}

/* line 2817, ../../../src/scss/screen.scss */
.float-right {
  float: right !important;
}

/* line 2822, ../../../src/scss/screen.scss */
.text-center {
  text-align: center !important;
}

/* line 2826, ../../../src/scss/screen.scss */
.text-left {
  text-align: left !important;
}

/* line 2830, ../../../src/scss/screen.scss */
.text-right {
  text-align: right !important;
}

/* line 2835, ../../../src/scss/screen.scss */
.fonts-body {
  font-family: "freightsans_regular", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  text-transform: none;
  letter-spacing: 0;
}

/* line 2839, ../../../src/scss/screen.scss */
.fonts-display {
  font-family: "freightsans_regular", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 3px;
}

/* line 2843, ../../../src/scss/screen.scss */
.fonts-display-secondary {
  font-family: "neuzeitgrobold", sans-serif;
  text-transform: none;
  letter-spacing: -3px;
}

/* line 2847, ../../../src/scss/screen.scss */
.fonts-display-cnd {
  font-family: "neuzeitgroextblack", sans-serif;
  text-transform: none;
  letter-spacing: 0;
}

/* line 2852, ../../../src/scss/screen.scss */
.overflow-hidden {
  overflow: hidden !important;
}

/* line 2856, ../../../src/scss/screen.scss */
.overflow-visible {
  overflow: visible !important;
}

/* line 2861, ../../../src/scss/screen.scss */
.margin-none {
  margin: 0 !important;
}

/* line 2865, ../../../src/scss/screen.scss */
.margin-top-none {
  margin-top: 0 !important;
}

/* line 2869, ../../../src/scss/screen.scss */
.margin-right-none {
  margin-right: 0 !important;
}

/* line 2873, ../../../src/scss/screen.scss */
.margin-bottom-none {
  margin-bottom: 0 !important;
}

/* line 2877, ../../../src/scss/screen.scss */
.margin-left-none {
  margin-left: 0 !important;
}

/* line 2881, ../../../src/scss/screen.scss */
.margin-top-small {
  margin-top: .55em !important;
}

/* line 2886, ../../../src/scss/screen.scss */
.padding-none {
  padding: 0 !important;
}

/* line 2890, ../../../src/scss/screen.scss */
.padding-top-none {
  padding-top: 0 !important;
}

/* line 2894, ../../../src/scss/screen.scss */
.padding-right-none {
  padding-right: 0 !important;
}

/* line 2898, ../../../src/scss/screen.scss */
.padding-bottom-none {
  padding-bottom: 0 !important;
}

/* line 2902, ../../../src/scss/screen.scss */
.padding-left-none {
  padding-left: 0 !important;
}

/* line 2907, ../../../src/scss/screen.scss */
.colour-bg {
  color: whitesmoke !important;
}

/* line 2911, ../../../src/scss/screen.scss */
.colour-body {
  color: #6b6b6b !important;
}

/* line 2915, ../../../src/scss/screen.scss */
.colour-mid-dark {
  color: #6a6a65 !important;
}

/* line 2922, ../../../src/scss/screen.scss */
.clearfix,
.container,
.list-hex-grid {
  *zoom: 1;
}
/* line 38, ../../../../../../usr/local/rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/utilities/general/_clearfix.scss */
.clearfix:after,
.container:after,
.list-hex-grid:after {
  content: "";
  display: table;
  clear: both;
}

/* line 2929, ../../../src/scss/screen.scss */
.position-relative {
  position: relative !important;
}

/* line 2933, ../../../src/scss/screen.scss */
.position-absolute {
  position: absolute !important;
}

/* line 2938, ../../../src/scss/screen.scss */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
}

/* line 2953, ../../../src/scss/screen.scss */
.no-touch.js .interactive div.hex {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
}

/* line 2958, ../../../src/scss/screen.scss */
.hexalert {
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

/* line 2966, ../../../src/scss/screen.scss */
.special {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
}

/* line 2975, ../../../src/scss/screen.scss */
.no-border {
  border: none;
}

/* line 2979, ../../../src/scss/screen.scss */
#ie6 {
  padding-bottom: 30px;
  display: none;
}